// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['gjliknarmal','gjmenntamal','gjmenningarmal','gjumhverfismal','gjsamtals']

  connect() {
    console.log("Hello, Stimulus!")
  }
	
  skyrslaGjafirLiknarmalValueChanged() {
    gjlikn = this.gjliknarmalTarget.value
    this.gjsamtTarget.textContent = gjlikn
    console.log("Gildi líknarmála breyttist! ${gjliknarmal}", this.element)
  }

  change() {
    const gjlikn = Number(this.gjliknarmalTarget.value)
    const gjmennta = Number(this.gjmenntamalTarget.value)
    const gjmenningar = Number(this.gjmenningarmalTarget.value)
    const gjumhverfis = Number(this.gjumhverfismalTarget.value)
    this.gjsamtalsTarget.value=gjlikn+gjmennta+gjmenningar+gjumhverfis
  }

  changesambskyrsl() {
    const gjlikn = Number(this.gjliknarmalTarget.value)
    const gjmennta = Number(this.gjmenntamalTarget.value)
    const gjmenningar = Number(this.gjmenningarmalTarget.value)
    const gjumhverfis = Number(this.gjumhverfismalTarget.value)
    this.gjsamtalsTarget.value=gjlikn+gjmennta+gjmenningar+gjumhverfis
  }

}
