// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['artol','titill','titlefel']

  connect() {

  }

  selectionChange() {
    const valar = String(this.artolTarget.value)
    this.titillTarget.textContent="Héraðssambönd - samtölur - " + valar
  	Rails.ajax({
  		url: '/sambands/prufa',
  		type: 'GET',
  		dataType: 'script',
  		data : 'artal='+valar,
  		success: function(response){}
  	});
  }

  selectionArSambChange() {
    const valar = String(this.artolTarget.value)
    this.titillTarget.innerHTML="<strong>Staða skýrslna fyrir félög undir samböndum: "+ valar + "</strong>"
    Rails.ajax({
      url: '/sambands/stadaFelSkyrsl',
      type: 'GET',
      dataType: 'script',
      data : 'artal='+valar,
      success: function(response){}
    });
  }
	
}
